import { destination } from '../../../modules/utilities';

export default class Query {
    // @todo seperate params for each type to separate classes
    /**
     * @param {string} type - The type/index of search to be performed
     * @param {array} addiction - The addiction (id(s)) to be searched
     * @param {boolean} online - Whether or not to search online seminars
     * @param {boolean} group - Whether or not to search group seminars
     * @param {number} perPage - The number of results to return per page
     * @param {number} page - The page number to return
     * @param {string} sort - The sortby method to return the results in, either 'soonest' or 'distance'
     * @param {number} distance - The distance to search from the geolocation provided via location
     * @param {object} location - The geolocation lat & lng hash to search with
     * @param {string} startDate - The start date to search for
     * @param {string} endDate - The end date to search for
     * @param {string} country - The country (slug) to be searched
     * @param {array} centreLocations - The centre locations (id(s)) to be searched
     * @param {boolean} voucher - Whether or not to search voucher products
     * @param {number} multiSiteId - The multisite id to prefix document ids with
     * @param {string} headOfficeId - The id of the head office online location to search
     * @param {boolean} showHeadOffice - Whether or not to search for the head office location
     * @param {boolean} hideOnBookingJourney - Whether or not to exclude hidden booking journey
     * @param {array} excludedLocations - Locations that should be excluded from booking journey
     */
    constructor(
        type = '',
        addiction = [],
        online = null,
        perPage = 10,
        page = 1,
        sort = 'distance',
        distance = 200,
        location = null,
        startDate = null,
        endDate = null,
        country = '',
        centreLocations = [],
        voucher = null,
        multiSiteId = 1,
        headOfficeId = '',
        showHeadOffice = false,
        hideOnBookingJourney = false,
        excludedLocations = []
    ) {
        if (type === '') {
            return {};
        }

        const isTaster = type === 'taster';
        if (isTaster) {
            type = 'seminar';
        }

        const isProduct = ['seminar', 'ovp', 'onetoone'].includes(type);
        const isTax = ['franchise'].includes(type);

        let query = {
            query: {
                bool: {
                    must: [
                        {
                            match: {
                                _index: `${type}`,
                            },
                        },
                    ],
                    must_not: [],
                    should: [],
                },
            },
            size: perPage,
            from: (page - 1) * perPage,
            sort: [],
            stored_fields: ['_source'],
        };

        if (!isTax) {
            query.query.bool.must.push({
                match: {
                    status: 'publish',
                },
            });
        }

        if (isProduct) {
            query.query.bool.must.push({
                match: {
                    inStock: true,
                },
            });

            query.query.bool.must_not.push({
                match: {
                    productVisiblity: 'hidden',
                },
            });

            if (online !== null) {
                query.query.bool.must.push({
                    match: {
                        online: online,
                    },
                });
            }

            if (voucher !== null) {
                query.query.bool.must.push({
                    match: {
                        voucher: voucher,
                    },
                });
            }

            if (hideOnBookingJourney && excludedLocations.length > 0) {
                query.query.bool.must_not.push({
                    bool: {
                        should: excludedLocations.map(excludedLocation => ({
                            match: {
                                seminar_location_id: excludedLocation,
                            },
                        })),
                    },
                });
            }

            if (type === 'ovp' && voucher !== true) {
                query.query.bool.must.push({
                    match: {
                        type: 'grouped',
                    },
                });
            } else if (type === 'seminar') {
                query.query.bool.must.push({
                    match: {
                        taster: isTaster,
                    },
                });

                if (voucher !== true) {
                    query.query.bool.must.push({
                        range: {
                            start_date: {
                                gte: 'now',
                                format: 'date_optional_time',
                            },
                        },
                    });

                    query.query.bool.must.push({
                        range: {
                            end_date: {
                                gte: 'now',
                                format: 'date_optional_time',
                            },
                        },
                    });
                }
            }
        }

        if (
            location !== null &&
            location.lat !== '' &&
            location.lng !== '' &&
            (online === null || !online)
        ) {
            query.script_fields = {
                distance: {
                    script: {
                        inline:
                            "doc['location'].arcDistance(params.lat, params.lon) * 0.001 * 0.621371",
                        lang: 'painless',
                        params: {
                            lat: parseFloat(location.lat),
                            lon: parseFloat(location.lng),
                        },
                    },
                },
            };
        }

        if (
            voucher !== true &&
            sort === 'distance' &&
            location !== null &&
            location.lat !== '' &&
            location.lng !== ''
        ) {
            query.sort.push({
                _geo_distance: {
                    location: {
                        lat: parseFloat(location.lat),
                        lon: parseFloat(location.lng),
                    },
                    order: 'asc',
                    ignore_unmapped: true,
                },
            });

            if (isProduct) {
                query.sort.push({
                    start_date: 'asc',
                });
            }

            if (distance > 0) {
                const lat = parseFloat(location.lat);
                const lng = parseFloat(location.lng);
                const topLeft = destination(lat, lng, 315, distance);
                const bottomRight = destination(lat, lng, 135, distance);

                query.query.bool.filter = [
                    {
                        geo_distance: {
                            distance: `${distance}mi`,
                            location: {
                                lat: lat,
                                lon: lng,
                            },
                        },
                    },
                    {
                        geo_bounding_box: {
                            location: {
                                top_left: topLeft,
                                bottom_right: bottomRight,
                            },
                        },
                    },
                ];
            }
        } else if (voucher !== true && sort === 'soonest') {
            query.sort.push({
                start_date: 'asc',
            });

            if (
                Object.prototype.toString.call(startDate) === '[object Date]' ||
                startDate !== null ||
                Object.prototype.toString.call(endDate) === '[object Date]' ||
                endDate !== null
            ) {
                if (startDate !== null) {
                    startDate.setUTCHours(0, 0, 0, 0);
                } else {
                    startDate = new Date();
                }

                let range = {
                    gte: startDate.toISOString(),
                    format: 'date_optional_time',
                };
                if (endDate !== null) {
                    endDate.setUTCHours(0, 0, 0, 0);
                    range.lte = endDate.toISOString();
                }

                query.query.bool.must.push({
                    range: {
                        start_date: range,
                    },
                });
            }
        }

        if (addiction.length > 0) {
            query.query.bool.must.push({
                bool: {
                    minimum_should_match: 1,
                    should: addiction.map(addictionId => ({
                        match: {
                            addictions_ids: `${addictionId}`,
                        },
                    })),
                },
            });
        }

        if (centreLocations.length > 0 && !online) {
            query.query.bool.must.push({
                bool: {
                    minimum_should_match: 1,
                    should: centreLocations.map(centreLocation => ({
                        match: {
                            seminar_location_id: centreLocation,
                        },
                    })),
                },
            });
        }

        // Need to combine the logic to fetch user's or head office incase they are in a different country
        if (country !== '') {
            if (online && headOfficeId !== '' && showHeadOffice) {
                query.query.bool.must.push({
                    bool: {
                        should: [
                            {
                                match: {
                                    countries_codes: `${country}`,
                                },
                            },
                            {
                                match: {
                                    seminar_location_id: headOfficeId,
                                },
                            },
                        ],
                        minimum_should_match: 1,
                    },
                });
            } else {
                query.query.bool.must.push({
                    match: {
                        countries_codes: `${country}`,
                    },
                });
            }
        } else if (headOfficeId !== '' && showHeadOffice) {
            query.query.bool.must.push({
                match: {
                    seminar_location_id: headOfficeId,
                },
            });
        }

        if (type === 'location' && multiSiteId) {
            query.query.bool.must.push({
                prefix: {
                    id: `${multiSiteId}_`,
                },
            });
        }

        return query;
    }
}
