import React, { useEffect, useState } from "react";
import Modal from "../Utilities/Modal";
import YoutubeEmbed from "../Utilities/YoutubeEmbed";
import VimeoEmbed from '../Utilities/VimeoEmbed';

const VideoReview = ({ review }) => {
    const [author, setAuthor] = useState(null);
	const [country, setCountry] = useState(null);
    const [isCeleb, setIsCeleb] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);
    const [embed, setEmbed] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(null);
    const [addiction, setAddiction] = useState(null);
    const [schema, setSchema] = useState(null);

    useEffect(() => {
        setAuthor(review.author ?? null);
        setCountry(review.country ?? null);
        setIsCeleb(review.type == 'celebrity' ?? false);
        setThumbnail(review.thumbnail ?? null);
        setEmbed(review.embed ?? null);
        setAspectRatio(review.aspect_ratio ?? 'landscape');
        setAddiction(review.addictions ?? null);
        setSchema(review.schema ?? null);
    }, [review]);

	const [showModal, setShowModal] = useState(false);

	const modalContent = () => 
		review.embed.includes('vimeo') ? (
            <VimeoEmbed url={review.embed} active={showModal} />
        ) : (
            <YoutubeEmbed url={review.embed} active={showModal} />
        );

    function handleModalClose() {
        setShowModal(false);
    }

	return (
		<li className={'client-reviews__review client-reviews__review--video' + (isCeleb ? ' client-reviews__review--celeb' : '')}>
			<div className="review__video-thumb">
				{thumbnail && <img src={thumbnail} />}
				{embed && <span className="review__play-button material-icons" onClick={() => { setShowModal(!showModal) }}>
					play_circle_outline
				</span>}
			</div>
			<div className='review-info-wrapper'>
				<div className="review__info">
					{author && <span className="review__author">{author}</span>}{country && author && ','}
					{country && <span className="review__country">{country}</span>}
					{addiction && <span className="review__addiction">{addiction}</span>}
				</div>
			</div>
			{embed && showModal && <Modal handleShow={showModal} content={modalContent()} handleModalClose={handleModalClose} themeClass={`modal--${aspectRatio}`}></Modal>}
			{schema &&
                <script type="application/ld+json">{schema}</script>
            }
		</li>
	);
};

export default VideoReview;